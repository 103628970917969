













import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
    data(): {
        isLoading: boolean;
    } {
        return {
            isLoading: true,
        };
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchLevels: "users/fetchLevels",
            fetchProfile: "auth/fetchProfile",
            // fetchClaims: "auth/fetchClaims",
            fetchCompanyProfile: "company/fetchProfile",
            fetchReportList: "reports/fetchReportList",
        }),
        async setupApp() {
            try {
                await this.fetchUsers();
                // await this.fetchLevels();
                await this.fetchProfile();
                // await this.fetchCompanyProfile();
                // await this.fetchClaims();
                // if (this.reports.length === 0) {
                //     await this.fetchReportList();
                // }
            } catch (e) {
                this.isLoading = false;
                this.$bvToast.toast("Error", { title: "Error while fetching data!", variant: "error", solid: true });
            } finally {
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        await this.setupApp();
    },
});
